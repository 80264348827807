import React, {Component, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';

import roles from "../tables/Authorization/UserRoles";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});
const LoginLayout = Loadable({
    loader: () => import('../tables/Authorization/login_page'),
    loading: Loader
});

class App extends Component {
    render() {

        return (
            <Aux>
                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {/*{menu}*/}
                            <Route path="/login" component={LoginLayout}/>
                            {(
                                localStorage.getItem("userRole") === roles.founder.role ||
                                localStorage.getItem("userRole") === roles.manager.role ||
                                localStorage.getItem("userRole") === roles.admin.role) ? (
                                <Route path="/" component={AdminLayout}/>
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: "/login",
                                    }}
                                />
                            )}
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
